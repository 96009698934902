//
// -- Ejemplo --
//

// h1 {
//   color: $blue;
// }

$font-family-ubuntu: 'Ubuntu' !default;
$font-family-base: $font-family-ubuntu !default;

html {
  position: relative;
  min-height: 100%;
}

#root,
body,
html {
}

.container {
  padding: 50px;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
}

.footer {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  height: 60px;
}
.navbar {
  .navbar-brand {
    height: 40px;
    width: 100px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
.full-height {
  height: calc(100vh - 120px);
}
.carousel-home {
  margin: 0 -15px;
  width: auto;
}
.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
.custom-data-table {
  min-height: 350px;
}
.table {
  font-size: 13px;
}
.table {
  .form-group {
    margin-bottom: 0rem;
    padding-left: 0;
    padding-right: 0;
  }
  td {
    vertical-align: inherit;
  }
  .form-control {
    height: 30px;
    font-size: 0.8rem;
    padding-left: 5px;
  }
  .btn-sm {
    padding: 0.15rem 0.37rem;
    line-height: 1;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgba(0, 0, 0, 0.15);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.15);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.15);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  .btn-old {
    background-image: linear-gradient(rgb(255, 255, 255), rgb(230, 230, 230));
    &:hover {
      background-image: none;
    }
  }
  .btn-old-red {
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
    &:hover {
      background-image: none;
    }
  }
  .btn-old-green {
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    &:hover {
      background-image: none;
    }
  }
}
.clickable {
  cursor: pointer;
}
.table > thead {
  /*color header table*/
  background: #333;
  color: white;
}
.btn-light {
  border-color: gray;
}
.btn {
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  border-radius: 55px;
  text-transform: uppercase !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 11.5px !important;
  letter-spacing: 0.1em !important;
  padding: 10px 10px !important;

  &.btn-primary {
    border-color: $blue-primary !important;
    background-color: $blue-primary;
    color: white;
    box-shadow: 1px 1px 15px rgba(39, 67, 178, 0.55) !important;
    &:hover {
      background-color: $blue-primary-dark !important;
    }
    &:active {
      background-color: $blue-primary-dark !important;
    }
  }

  &.btn-outline-primary {
    box-shadow: none;
    background-color: white;
    color: $blue-primary;
    border: 1px solid $blue-primary !important;
    box-sizing: border-box;
    &:hover {
      background-color: $blue-third !important;
    }
    &.active {
      border-color: $blue-primary !important;
      background-color: $blue-primary !important;
      color: white;
      box-shadow: 1px 1px 15px rgba(39, 67, 178, 0.55) !important;
    }
    &.without-box-shadow {
      box-shadow: none !important;
    }
  }
  &.btn-data-primary {
    border-color: $blue-primary !important;
    background-color: $blue-primary;
    box-shadow: 1px 1px 7px $blue-primary !important;
    color: white;
    &.btn-lighted {
      border-color: #7384c7 !important;
      background-color: #7384c7 !important;
      box-shadow: 1px 1px 7px #7384c7 !important;
    }
    &.without-box-shadow {
      box-shadow: none !important;
    }
  }
  &.btn-secoundary {
    border-color: $green-primary;
    background-color: $green-primary;
    color: #232323;
    box-shadow: 1px 1px 7px rgba(11, 196, 62, 0.5);
  }
  &.btn-outline-secoundary {
    background-color: $black-primary;
    color: $green-primary;
    border: 1px solid $green-primary;
    box-sizing: border-box;
  }

  &.btn-cancel {
    border-color: $gray-medium;
    background-color: $gray-primary;
    color: $black-primary;
    box-shadow: 1px 1px 7px rgba(11, 196, 62, 0.5);
  }
  &.btn-outline-cancel {
    background-color: #ffffff;
    color: $gray-medium;
    border: 1px solid $gray-medium;
    box-sizing: border-box;
  }
  &.btn-danger {
    border-color: $red-primary !important;
    background-color: $red-primary;
    color: white !important;
    box-shadow: 1px 1px 7px $red-primary !important;
  }
  &.btn-data-danger {
    border-color: #f5a3a3 !important;
    background-color: #f5a3a3;
    box-shadow: 1px 1px 7px #f5a3a3 !important;
    color: $black-primary !important;
    &.btn-lighted {
      border-color: #eacfcf !important;
      background-color: #eacfcf !important;
      box-shadow: 1px 1px 7px #eacfcf !important;
    }
    &.without-box-shadow {
      box-shadow: none !important;
    }
  }
  &.btn-outline-danger {
    box-shadow: none;
    background-color: white !important;
    color: $black-primary !important;
    border: 1px solid $red-secondary !important;
    box-sizing: border-box;
    &.active {
      border-color: $red-secondary !important;
      background-color: $red-secondary !important;
      color: white !important;
      box-shadow: 1px 1px 7px $red-secondary !important;
    }
  }

  &.btn-light {
    border-color: $gray-primary;
    background-color: $gray-primary;
  }
  &.btn-outline-light {
    border-color: $gray-primary;
    color: #999;
  }
  &.btn-ecommerce {
    background: linear-gradient(82.69deg, rgba(255, 118, 118, 1) 0%, rgba(37, 66, 178, 1) 100%);
    color: white;
    border: none !important;
    height: 32px;
  }
  &.disabled {
    cursor: not-allowed !important;
  }
  &.disabled.disabled-grey {
    background: #dfdfde !important;
    border: none;
    color: $black-primary !important;
    font-weight: bold;
    box-shadow: 0px 0px 0px !important;
    cursor: not-allowed !important;
  }
}

.info-table-delivery {
  border-width: 0px !important;
  td,
  th {
    background: #fff;
    border: 1px dashed #dedfe0;
    vertical-align: middle;
  }
  th {
    background-color: #f7f7f7;
  }
}
.asReadOnly {
  background-color: #e9ecef;
}
.quality-state-rejected {
  opacity: 0.5;
  text-decoration: line-through;
}
.dropzone-section {
  width: 100%;
  text-align: center;
  border: lightgray solid;
  border-radius: 5px;
  border-width: thin;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.dropzone-container {
  color: gray;
  height: 100%;

  input {
    height: 100%;
  }
}
// MARGENES
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-5 {
  margin-right: 5px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-t-15 {
  padding-top: 15px;
}
.opacity-8 {
  opacity: 0.8;
}
.hidden {
  display: none;
}
.capitalize {
  text-transform: capitalize;
}
