@import '~bootstrap/scss/functions';

.card-form {
  height: 100%;
  padding: 50px !important;
  padding-top: 30px !important;
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 40px;
  box-shadow: -4px -4px 20px rgba(255, 118, 118, 0.1), 4px 4px 37px rgba(245, 163, 163, 0.15);
  &.delivery-request-index {
    @media (max-width: 768px) {
      padding: 40px 30px 40px !important;
    }
  }
}

.table-text-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
}

.fc-button {
  border-radius: 55px !important;
  text-transform: uppercase !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 11.5px !important;
  letter-spacing: 0.1em !important;
  padding: 5px 10px !important;
  box-shadow: none !important;
  background-color: white !important;
  border: 1px solid #2743b2 !important;
  box-sizing: border-box !important;
  color: black !important;
}

.inner-card {
  min-height: 270px;
  padding: 20px 15px 20px 15px;
  border-radius: 30px;
  border-color: #9fabe5;
  border-style: solid;
  border-width: 1px;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}

.task-move-cal {
  background-color: #2743b2;
  color: white;
  border-radius: 40px;
  height: 40px;
  align-items: center;
}

.inner-card-container {
  margin-right: -30px !important;
  margin-left: -30px !important;
}

.paragraph1 {
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: capitalize !important;
}

.label {
  font-size: 12px;
  letter-spacing: 0.04em;
  font-weight: 800;
}

.paragraph2 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.subtitle1 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.04em;
}

.btn-status {
  border-radius: 40px;
  &.pending {
    background-color: #f8c203;
  }
  &.finalized {
    background-color: #2dd25b;
    // padding: 7px;
  }
  &.inProcess {
    background-color: #bfc4db;
  }
  &.blocked {
    background-color: rgb(240, 9, 9);
  }
  &.failed {
    background-color: rgb(240, 9, 9);
    color: white;
  }
}

.reactivate {
  border: 1px solid red !important;
  border-radius: 40px;
}

.request-show-image {
  height: 300px;
  border-radius: 30px;
}

.fix-bottom {
  margin-top: auto;
}

.icon-clickable {
  color: #7384c7;
  cursor: pointer;
}

.icon-disabled {
  color: #808080;
  opacity: 50%;
}

/* React Datepicker styles override */
.react-datepicker-wrapper {
  width: unset !important;
}

.react-datepicker {
  border-radius: 30px !important;
  padding: 5px;

  .react-datepicker__month-container {
    .react-datepicker__header {
      border-bottom: unset !important;
      background-color: unset !important;
      .react-datepicker__day-names {
        font-weight: 700;
        font-size: 10px !important;
      }
      .react-datepicker__current-month {
        font-weight: 500;
        font-size: 14px !important;
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day {
          font-size: 10px !important;
        }
        .react-datepicker__day--selected {
          border-radius: 50% !important;
          background-color: #2743b2 !important;
          border-color: #2743b2 !important;
        }
        .react-datepicker__day--outside-month {
          opacity: 0.2;
        }
      }
    }
  }
}

#week-button,
#day-button {
  padding: 5px 10px !important;
  border-radius: 55px !important;
  text-transform: uppercase !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 11.5px !important;
  letter-spacing: 0.1em !important;
  box-shadow: none !important;
  border: 1px solid #2743b2 !important;
  box-sizing: border-box !important;
}

.delivery-order {
  border-color: #bfc4db !important;
  transition: 0.3s;
  &:hover {
    background: #eceef5;
    border-color: #eceef5 !important;
    transition: 0.3s;
  }
  &.active {
    border-color: #2743b2;
    background: #bfc4db;
  }
}

.text-clip-nowrap {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
